<!-- assignments.vue -->
<template>
  <main class="flex-grow container mx-auto p-4 sm:p-6">
    <div class="text-center">
      <!-- Week Navigation -->
      <div class="flex flex-col sm:flex-row justify-between items-center mb-6">
        <button
            @click="previousWeek"
            class="bg-gray-300 hover:bg-gray-400 text-black font-semibold py-2 px-4 rounded-l mb-2 sm:mb-0 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-white"
        >
          &lt; Previous Week
        </button>
        <h3 class="text-2xl sm:text-3xl font-semibold text-gray-900 dark:text-gray-100">
          Week of {{ formatDate(currentWeekStart) }}
        </h3>
        <button
            @click="nextWeek"
            class="bg-gray-300 hover:bg-gray-400 text-black font-semibold py-2 px-4 rounded-r mt-2 sm:mt-0 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-white"
        >
          Next Week &gt;
        </button>
      </div>

      <!-- View Toggle -->
      <div class="flex justify-center mb-6 space-x-2">
        <button
            @click="isWeekView = true"
            :class="[
            isWeekView ? 'bg-indigo-600 text-white' : 'bg-gray-300 text-black dark:bg-gray-700 dark:text-white',
            'hover:bg-indigo-500 font-semibold py-2 px-4 rounded'
          ]"
        >
          Week View
        </button>
        <button
            @click="isWeekView = false"
            :class="[
            !isWeekView ? 'bg-indigo-600 text-white' : 'bg-gray-300 text-black dark:bg-gray-700 dark:text-white',
            'hover:bg-indigo-500 font-semibold py-2 px-4 rounded'
          ]"
        >
          Day View
        </button>
      </div>

      <!-- Day Navigation -->
      <div v-if="!isWeekView" class="flex flex-wrap justify-center mb-6 space-x-2">
        <button
            v-for="(day, index) in daysOfWeek"
            :key="index"
            @click="currentDayIndex = index"
            :class="[
            currentDayIndex === index ? 'bg-indigo-600 text-white' : 'bg-gray-300 text-black dark:bg-gray-700 dark:text-white',
            'hover:bg-indigo-500 font-semibold py-2 px-4 rounded mb-2'
          ]"
        >
          {{ day }}
        </button>
      </div>

      <!-- Loading Spinner -->
      <div v-if="isLoading" class="flex justify-center items-center h-64">
        <div class="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-indigo-500"></div>
      </div>

      <!-- Assignment List -->
      <div
          v-else
          class="bg-slate-35 dark:bg-gray-800 bg-opacity-90 shadow-2xl rounded-2xl p-4 sm:p-8"
      >
        <h4 class="text-xl sm:text-2xl font-semibold mb-6 text-gray-900 dark:text-white">
          {{ isWeekView ? "Week's Assignments" : daysOfWeek[currentDayIndex] + "'s Assignments" }}
        </h4>

        <!-- Week View -->
        <div v-if="isWeekView" class="flex flex-wrap -mx-2">
          <div
              v-for="(assignments, day) in weeklyAssignments"
              :key="day"
              class="w-full sm:w-1/2 md:w-1/3 lg:w-1/5 px-2 mb-4"
          >
            <h5 class="text-lg sm:text-xl font-bold mb-4 text-gray-900 dark:text-white">{{ day }}</h5>
            <ul v-if="assignments.length" class="space-y-4 mb-6">
              <li
                  v-for="assignment in assignments"
                  :key="assignment.assignment_id"
                  class="bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 rounded-lg p-4 shadow-md transition duration-300 cursor-pointer"
                  @click="openModal(assignment)"
              >
                <div class="flex flex-col text-gray-900 dark:text-gray-100">
                  <div class="flex-1">
                    <h5 class="text-base sm:text-lg font-bold mb-1">
                      {{ assignment.section_name }}
                    </h5>
                    <p class="text-gray-600 dark:text-gray-300 text-sm">
                      {{ truncateText(assignment.short_description, 50) }}
                    </p>
                  </div>
                  <!-- Status Icons -->
                  <div class="mt-2 flex space-x-2">
                    <span
                        v-for="status in statuses"
                        :key="status.value"
                        @click.stop="updateStatus(assignment, status.value)"
                        :aria-label="status.label"
                        class="cursor-pointer inline-flex items-center justify-center relative group w-6 h-6"
                    >
                      <!-- Updated SVG Icons -->
                      <!-- Icons with appropriate color classes -->
                      <svg
                          v-if="status.value === 'not-started'"
                          xmlns="http://www.w3.org/2000/svg"
                          :class="[
                          'w-6 h-6',
                          assignment.status === status.value ? status.activeColorClass : 'text-gray-500'
                        ]"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                      >
                        <!-- Cross (X) icon for Not Started -->
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>

                      <svg
                          v-else-if="status.value === 'in-progress'"
                          xmlns="http://www.w3.org/2000/svg"
                          :class="[
                          'w-6 h-6',
                          assignment.status === status.value ? status.activeColorClass : 'text-gray-500'
                        ]"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                      >
                        <!-- Clock icon for In Progress -->
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 8v4l3 3m6 0A9 9 0 1112 3a9 9 0 019 9z"
                        />
                      </svg>
                      <svg
                          v-else-if="status.value === 'complete'"
                          xmlns="http://www.w3.org/2000/svg"
                          :class="[
                          'w-6 h-6',
                          assignment.status === status.value ? status.activeColorClass : 'text-gray-500'
                        ]"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                      >
                        <!-- Checkmark icon for Complete -->
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M5 13l4 4L19 7"
                        />
                      </svg>
                      <!-- Tooltip -->
                      <span
                          class="absolute bottom-full mb-2 w-max bg-gray-200 dark:bg-gray-700 text-black dark:text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap pointer-events-none"
                      >
                        {{ status.label }}
                      </span>
                    </span>
                  </div>
                </div>
              </li>
            </ul>
            <div v-else class="text-gray-600 dark:text-gray-400">
              <p>
                No assignments due on this day.
              </p>
            </div>
          </div>
        </div>

        <!-- Day View -->
        <div v-else>
          <ul v-if="dailyAssignments.length" class="space-y-4">
            <li
                v-for="assignment in dailyAssignments"
                :key="assignment.assignment_id"
                class="bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 rounded-lg p-6 shadow-md transition duration-300 cursor-pointer"
                @click="openModal(assignment)"
            >
              <div class="flex flex-col sm:flex-row justify-between items-start text-gray-900 dark:text-gray-100">
                <div class="flex-1 pr-4">
                  <h5 class="text-lg font-bold mb-2">
                    <i class="fas fa-book mr-2 text-indigo-400"></i>
                    {{ assignment.section_name }}
                  </h5>
                  <p class="text-gray-600 dark:text-gray-300">
                    <!-- Truncate long descriptions -->
                    {{ truncateText(assignment.short_description, 100) }}
                    <span
                        v-if="assignment.short_description.length > 100"
                        @click.stop="openModal(assignment)"
                        class="text-indigo-400 hover:underline cursor-pointer"
                    >
                      Read More
                    </span>
                  </p>
                </div>
                <div class="text-right mt-4 sm:mt-0">
                  <p class="text-gray-500 dark:text-gray-400">
                    Due: {{ formatDate(assignment.date_due) }}
                  </p>
                  <!-- Status Pills -->
                  <div class="mt-4 flex space-x-2">
                    <span
                        v-for="status in statuses"
                        :key="status.value"
                        @click.stop="updateStatus(assignment, status.value)"
                        :class="[
                        'px-3 py-1 rounded-full text-sm font-semibold cursor-pointer flex items-center',
                        assignment.status === status.value
                          ? status.activeClass
                          : status.inactiveClass,
                      ]"
                    >
                      {{ status.label }}
                    </span>
                  </div>
                  <!-- Aggregated Status Counts -->
                  <p class="text-gray-500 dark:text-gray-400 mt-2">
                    <span v-if="assignment.status_counts">
                      <span v-if="assignment.status_counts['complete']">
                        {{ assignment.status_counts['complete'] }}
                        {{ pluralize(assignment.status_counts['complete'], 'person has', 'people have') }}
                        completed this assignment.
                      </span>
                    </span>
                  </p>
                  <!-- Delete Button for Custom Assignments -->
                  <button
                      v-if="assignment.user_task"
                      @click.stop="deleteAssignment(assignment)"
                      class="text-red-500 hover:text-red-700 mt-2"
                  >
                    <i class="fas fa-trash"></i> Delete
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div v-else class="text-gray-600 dark:text-gray-400">
            <p>
              <i class="fas fa-check-circle mr-2 text-green-500"></i>
              No assignments due on this day.
            </p>
          </div>
        </div>

        <!-- Add Custom Task Button -->
        <button
            @click="openAddTaskModal"
            class="mt-8 bg-green-600 hover:bg-green-700 text-white px-6 py-3 rounded-full font-semibold shadow-lg transition duration-300"
        >
          Add Custom Task
        </button>
      </div>
    </div>

    <!-- Modal for Assignment Description -->
    <div
      v-if="showModal"
      class="fixed inset-0 z-50 bg-black bg-opacity-70 flex justify-center items-center p-4"
      @click.self="closeModal"
    >
      <div
        class="bg-white dark:bg-gray-900 rounded-lg shadow-2xl p-4 sm:p-8 max-w-lg w-full relative text-center overflow-y-auto max-h-[90vh] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-thumb-gray-600 dark:scrollbar-track-gray-800"
      >
        <button
          @click="closeModal"
          class="absolute top-4 right-4 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-200 text-2xl"
        >
          <i class="fas fa-times"></i>
        </button>
        <h3 class="text-xl sm:text-2xl font-semibold mb-4 text-indigo-600 dark:text-indigo-400">
          {{ currentAssignment.section_name }}: {{ currentAssignment.short_description }}
        </h3>
        <p class="text-gray-700 dark:text-gray-300 mb-6 whitespace-pre-wrap">
          {{ currentAssignment.long_description }}
        </p>
        <div class="flex justify-center space-x-4">
          <button
            v-if="currentAssignment.user_task"
            @click="deleteAssignment(currentAssignment); closeModal()"
            class="bg-red-600 hover:bg-red-700 text-white px-6 py-2 rounded-full font-semibold shadow-md transition duration-300"
          >
            Delete
          </button>
          <button
            @click="closeModal"
            class="bg-indigo-600 hover:bg-indigo-700 text-white px-6 py-2 rounded-full font-semibold shadow-md transition duration-300"
          >
            Close
          </button>
        </div>
      </div>
    </div>


    <!-- Modal for Adding Custom Task -->
    <div
      v-if="showAddTaskModal"
      class="fixed inset-0 z-50 bg-black bg-opacity-70 flex justify-center items-center p-4"
      @click.self="closeAddTaskModal"
    >
      <div
        class="bg-white dark:bg-gray-900 rounded-lg shadow-2xl p-4 sm:p-8 max-w-lg w-full relative overflow-y-auto max-h-[90vh] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-thumb-gray-600 dark:scrollbar-track-gray-800"
      >
        <h3 class="text-2xl font-semibold mb-6 text-green-600 dark:text-green-400">
          Add Custom Task
        </h3>
        <form @submit.prevent="addTask">
          <div class="mb-4">
            <label for="section" class="block text-gray-700 dark:text-gray-400 mb-2">Section</label>
            <input
              v-model="newTask.section_name"
              id="section"
              type="text"
              class="w-full px-4 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-green-500"
              required
            />
          </div>
          <div class="mb-4">
            <label for="title" class="block text-gray-700 dark:text-gray-400 mb-2">Title</label>
            <input
              v-model="newTask.short_description"
              id="title"
              type="text"
              class="w-full px-4 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-green-500"
              required
            />
          </div>
          <div class="mb-4">
            <label for="dueDate" class="block text-gray-700 dark:text-gray-400 mb-2">Due Date</label>
            <input
              v-model="newTask.date_due"
              id="dueDate"
              type="date"
              class="w-full px-4 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-green-500"
              required
            />
          </div>
          <div class="mb-6">
            <label for="description" class="block text-gray-700 dark:text-gray-400 mb-2">Description</label>
            <textarea
              v-model="newTask.long_description"
              id="description"
              class="w-full px-4 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-green-500"
              rows="4"
              required
            ></textarea>
          </div>
          <div class="flex justify-end space-x-4">
            <button
              @click="closeAddTaskModal"
              type="button"
              class="bg-red-600 hover:bg-red-700 text-white px-6 py-2 rounded-full font-semibold shadow-md transition duration-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-full font-semibold shadow-md transition duration-300"
            >
              Add Task
            </button>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios';
import he from 'he';
import sanitizeHtml from 'sanitize-html';

export default {
  data() {
    return {
      token: null,
      showModal: false,
      showAddTaskModal: false,
      currentAssignment: null,
      newTask: {
        section_name: '',
        short_description: '',
        date_due: '',
        long_description: '',
      },
      assignments: [],
      currentDayIndex: 0,
      currentWeekStart: null,
      daysOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
      statuses: [
        {
          value: 'not-started',
          label: 'Not Started',
          activeClass: 'bg-red-700 text-white',
          inactiveClass: 'bg-red-50 text-red-600',
          activeColorClass: 'text-red-500',
        },
        {
          value: 'in-progress',
          label: 'In Progress',
          activeClass: 'bg-yellow-500 text-white',
          inactiveClass: 'bg-yellow-50 text-yellow-700',
          activeColorClass: 'text-yellow-500',
        },
        {
          value: 'complete',
          label: 'Complete',
          activeClass: 'bg-green-700 text-white',
          inactiveClass: 'bg-green-50 text-green-700',
          activeColorClass: 'text-green-500',
        },
      ],
      isLoading: false,
      isWeekView: true,
    };
  },
  computed: {
    dailyAssignments() {
      const currentDate = this.getDateByDay(
          this.currentWeekStart,
          this.currentDayIndex
      );
      const formattedDate = this.formatApiDate(currentDate);

      return this.assignments.filter((assignment) => {
        const assignmentDate = assignment.date_due.split('T')[0];
        return assignmentDate === formattedDate;
      });
    },
    weeklyAssignments() {
      const assignmentsByDay = {};
      for (let i = 0; i < this.daysOfWeek.length; i++) {
        const dayName = this.daysOfWeek[i];
        const currentDate = this.getDateByDay(this.currentWeekStart, i);
        const formattedDate = this.formatApiDate(currentDate);
        assignmentsByDay[dayName] = this.assignments.filter((assignment) => {
          const assignmentDate = assignment.date_due.split('T')[0];
          return assignmentDate === formattedDate;
        });
      }
      return assignmentsByDay;
    },
  },
  created() {
    this.currentDayIndex = this.getCurrentDayIndex();
    this.currentWeekStart = this.getCurrentWeekStart();
  },
  methods: {
    getCurrentDayIndex() {
      const today = new Date();
      let dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
      const hour = today.getHours();

      if (hour >= 16) {
        if (dayOfWeek === 5) {
          dayOfWeek = 1;
        } else if (dayOfWeek === 6 || dayOfWeek === 0) {
          dayOfWeek = 1;
        } else {
          dayOfWeek += 1;
        }
      }

      if (dayOfWeek === 6 || dayOfWeek === 0) {
        return 0;
      }

      return dayOfWeek - 1;
    },
    getCurrentWeekStart() {
      const today = new Date();
      const dayOfWeek = today.getDay();
      const hour = today.getHours();
      let weekStartDate;

      const isFridayAfter4pm = dayOfWeek === 5 && hour >= 16;
      const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;

      if (isFridayAfter4pm || isWeekend) {
        const daysUntilNextMonday = (8 - dayOfWeek) % 7 || 7;
        weekStartDate = new Date(today);
        weekStartDate.setDate(today.getDate() + daysUntilNextMonday);
      } else {
        const daysSinceMonday = (dayOfWeek + 6) % 7;
        weekStartDate = new Date(today);
        weekStartDate.setDate(today.getDate() - daysSinceMonday);
      }

      weekStartDate.setHours(0, 0, 0, 0);
      return weekStartDate;
    },
    extractToken() {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');

      if (token) {
        this.token = token;
        localStorage.setItem('token', token);

        urlParams.delete('token');
        const newUrl = window.location.pathname + '?' + urlParams.toString();
        window.history.replaceState({}, document.title, newUrl);
      } else {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
          this.token = storedToken;
        } else {
          const currentUrl = encodeURIComponent(window.location.href);
          window.location.href = `https://bcbackend.replit.app/login?originalUrl=${currentUrl}`;
        }
      }
    },
    isTokenExpired(token) {
      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        const expiry = payload.exp;
        const now = Math.floor(new Date().getTime() / 1000);
        return now > expiry;
      } catch (error) {
        console.error('Error decoding token:', error);
        return true;
      }
    },
    async fetchAssignments(showLoading = true) {
      if (!this.token || this.isTokenExpired(this.token)) {
        const currentUrl = encodeURIComponent(window.location.href);
        window.location.href = `https://bcbackend.replit.app/login?originalUrl=${currentUrl}`;
        return;
      }
      try {
        if (showLoading) {
          this.isLoading = true;
        }

        const start_date = this.formatApiDate(this.currentWeekStart);
        const end_date = this.formatApiDate(
            new Date(this.currentWeekStart.getTime() + 6 * 24 * 60 * 60 * 1000)
        );

        const response = await axios.get('https://bcbackend.replit.app/assignments', {
          params: {
            start_date,
            end_date,
          },
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });

        this.assignments = response.data.value;
      } catch (error) {
        console.error('Error fetching assignments:', error);
        if (error.response && error.response.status === 401) {
          const currentUrl = encodeURIComponent(window.location.href);
          window.location.href = `https://bcbackend.replit.app/login?originalUrl=${currentUrl}`;
        }
      } finally {
        if (showLoading) {
          this.isLoading = false;
        }
      }
    },
    nextWeek() {
      const nextWeekDate = new Date(this.currentWeekStart);
      nextWeekDate.setDate(this.currentWeekStart.getDate() + 7);
      this.currentWeekStart = nextWeekDate;
      this.currentDayIndex = 0;
      this.fetchAssignments();
    },
    previousWeek() {
      const previousWeekDate = new Date(this.currentWeekStart);
      previousWeekDate.setDate(this.currentWeekStart.getDate() - 7);
      this.currentWeekStart = previousWeekDate;
      this.currentDayIndex = 0;
      this.fetchAssignments();
    },
    formatDate(dateInput) {
      let date = dateInput;
      if (!(dateInput instanceof Date)) {
        date = new Date(dateInput);
      }
      return date.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      });
    },
    formatApiDate(date) {
      const isoString = date.toISOString().split('T')[0];
      return isoString;
    },
    getDateByDay(weekStart, dayIndex) {
      const date = new Date(weekStart.getTime());
      date.setDate(weekStart.getDate() + dayIndex);
      date.setHours(0, 0, 0, 0);
      return date;
    },
    cleanAssignmentDescription(html) {
      if (!html) return '';

      // Step 1: Replace <br>, <br/>, <br />, <p>, and </p> with newline characters
      let withLineBreaks = html.replace(/<br\s*\/?>/gi, '\n').replace(/<\/?p>/gi, '\n');

      // Step 2: Decode HTML entities
      withLineBreaks = he.decode(withLineBreaks);

      // Step 3: Strip any remaining HTML tags
      const cleanText = sanitizeHtml(withLineBreaks, { allowedTags: [], allowedAttributes: {} });

      return cleanText;
    },
    openModal(assignment) {
      const cleanedDescription = this.cleanAssignmentDescription(assignment.long_description);
      this.currentAssignment = { ...assignment, long_description: cleanedDescription };
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.currentAssignment = null;
    },
    openAddTaskModal() {
      this.showAddTaskModal = true;
    },
    closeAddTaskModal() {
      this.showAddTaskModal = false;
      this.newTask = {
        section_name: '',
        short_description: '',
        date_due: '',
        long_description: '',
      };
    },
    async addTask() {
      const dateParts = this.newTask.date_due.split('-');
      const assignmentDate = new Date(
          parseInt(dateParts[0], 10),
          parseInt(dateParts[1], 10) - 1,
          parseInt(dateParts[2], 10)
      );

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      assignmentDate.setHours(0, 0, 0, 0);

      if (assignmentDate < today) {
        alert('You cannot select a past date.');
        return;
      }

      const dayOfWeek = assignmentDate.getDay();

      if (dayOfWeek === 0 || dayOfWeek === 6) {
        alert('Assignments cannot be submitted on weekends.');
        return;
      }

      try {
        const response = await axios.post(
            'https://bcbackend.replit.app/custom_assignments',
            this.newTask,
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            }
        );

        const taskToAdd = response.data;
        taskToAdd.user_task = true;
        taskToAdd.status = 'not-started'; // Set initial status here
        this.assignments.push(taskToAdd);

        this.closeAddTaskModal();
      } catch (error) {
        console.error('Error adding custom task:', error);
        alert('An error occurred while adding the task. Please try again.');
      }
    },
    async deleteAssignment(assignment) {
      if (!confirm('Are you sure you want to delete this assignment?')) {
        return;
      }

      try {
        await axios.delete(
            `https://bcbackend.replit.app/custom_assignments/${assignment.assignment_id}`,
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            }
        );

        this.assignments = this.assignments.filter(
            (a) => a.assignment_id !== assignment.assignment_id
        );
      } catch (error) {
        console.error('Error deleting assignment:', error);
        alert('An error occurred while deleting the assignment. Please try again.');
      }
    },
    async updateStatus(assignment, status) {
      assignment.status = status;

      try {
        await axios.post(
            'https://bcbackend.replit.app/assignment_statuses',
            {
              assignment_id: assignment.assignment_id,
              status: status,
            },
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            }
        );

        await this.fetchAssignments(false);
      } catch (error) {
        console.error('Error updating assignment status:', error);
      }
    },
    truncateText(text, length) {
      if (text.length <= length) return text;
      return text.substring(0, length) + '...';
    },
    pluralize(count, singularVerbPhrase, pluralVerbPhrase) {
      return count === 1 ? singularVerbPhrase : pluralVerbPhrase;
    },
  },
  mounted() {
    this.extractToken();
    this.fetchAssignments();
  },
};
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}

.dark::-webkit-scrollbar-thumb {
  background: #4a5568;
}

.dark::-webkit-scrollbar-thumb:hover {
  background: #2d3748;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dark input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
</style>
