<template>
  <div class="min-h-screen bg-gray-50 dark:bg-gray-900 text-gray-900 dark:text-white transition-colors duration-300">
    <div class="max-w-7xl mx-auto py-10 px-4">
      <!-- Title -->
      <div class="text-center mb-10">
        <h1 class="text-4xl font-extrabold mb-4">Basketball Stats</h1>
        <p class="text-sm text-gray-500 dark:text-gray-400">
          View overall and detailed per-game stats.
        </p>
      </div>

      <!-- Overall Stats Card -->
      <div class="bg-white dark:bg-gray-800 shadow rounded-lg p-8 mb-12">
        <h2 class="text-3xl font-bold mb-6 text-center">Overall Stats (All Games)</h2>
        <div class="overflow-x-auto">
          <table class="w-full border-collapse text-base">
            <thead class="bg-gray-100 dark:bg-gray-700">
            <tr>
              <th class="text-left p-4 font-semibold">Player</th>
              <th class="text-left p-4 font-semibold">
                Average Points (excl. Dwight)
              </th>
              <th class="text-left p-4 font-semibold">Total Points</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="player in sortedPlayers"
                :key="player"
                class="border-b dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700"
            >
              <td class="p-4 font-medium">
                <!-- Click player's name to open the per-game modal -->
                <button
                    class="text-blue-600 dark:text-blue-400 hover:underline"
                    @click="openModal(player)"
                >
                  {{ player }}
                </button>
              </td>
              <td class="p-4">
                {{ calcAveragePoints(player).toFixed(2) }}
              </td>
              <td class="p-4">
                {{ overallStats[player]?.points ?? 0 }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="mt-12 bg-white dark:bg-gray-800 shadow rounded-lg p-8">
        <h2 class="text-2xl font-bold mb-4 text-center">Insights</h2>
        <div class="text-center space-y-2 text-base">
          <p>Total points scored by all players: <strong>{{ totalPointsAllPlayers }}</strong></p>
          <p>Total fouls by all players: <strong>{{ totalFoulsAllPlayers }}</strong></p>
          <p>Win/Loss Record: <strong>{{ wins }}-{{ losses }}</strong></p>
          <p>Win Percentage: <strong>{{ winPercentage.toFixed(2) }}%</strong></p>
        </div>
      </div>
    </div>

    <!-- Modal for Per-Game Stats -->
    <transition name="fade">
      <div
          v-if="showModal"
          class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      >
        <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 w-full max-w-3xl relative">
          <button
              @click="closeModal"
              class="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 text-xl"
          >
            &times;
          </button>
          <h3 class="text-2xl font-bold mb-4">Game-by-Game Stats for {{ selectedPlayer }}</h3>

          <div class="overflow-x-auto">
            <table class="w-full border-collapse text-base">
              <thead class="bg-gray-100 dark:bg-gray-700">
              <tr>
                <th class="text-left p-4 font-semibold">Game</th>
                <th class="text-left p-4 font-semibold">Points</th>
                <th class="text-left p-4 font-semibold">Fouls</th>
                <th class="text-left p-4 font-semibold">FTA</th>
                <th class="text-left p-4 font-semibold">FTM</th>
                <th class="text-left p-4 font-semibold">FT%</th>
                <th class="text-left p-4 font-semibold">Layups</th>
                <th class="text-left p-4 font-semibold">Injured</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(stats, index) in playerGameStats(selectedPlayer)"
                  :key="index"
                  class="border-b dark:border-gray-700"
              >
                <td class="p-4 font-medium">{{ gameNames[index] }}</td>
                <td class="p-4">{{ stats.points }}</td>
                <td class="p-4">{{ stats.fouls }}</td>
                <td class="p-4">{{ stats.fta }}</td>
                <td class="p-4">{{ stats.ftm }}</td>
                <td class="p-4">
                  {{
                    stats.fta > 0
                        ? ((stats.ftm / stats.fta) * 100).toFixed(1) + '%'
                        : 'N/A'
                  }}
                </td>
                <td class="p-4">{{ stats.layups }}</td>
                <td class="p-4">
                  <span v-if="injuredData[index]?.[selectedPlayer]" class="text-red-500 font-semibold">Yes</span>
                  <span v-else>No</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="mt-6 text-right">
            <button
                @click="closeModal"
                class="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-6 rounded"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

const allPlayers = [
  'Blake',
  'Wynton',
  'Paul',
  'Raphael',
  'Ryan',
  'Jack N',
  'Jack W',
  'Kyam',
  'Nathan',
  'Lucas',
  'Declan',
  'Shane',
  'Kush',
  'Viraaj',
    'Julian'
]

const gamesData = [
  // Game 1: no data
  {},

  // Game 2
  {
    'Blake': { fouls: 2, fta: 0, ftm: 0, layups: 0 },
    'Wynton': { fouls: 3, fta: 7, ftm: 4, layups: 4 },
    'Raphael': { fouls: 0, fta: 2, ftm: 1, layups: 0 },
    'Ryan': { fouls: 4, fta: 17, ftm: 4, layups: 12 },
    'Jack N': { fouls: 2, fta: 0, ftm: 0, layups: 2 },
    'Lucas': { fouls: 2, fta: 0, ftm: 0, layups: 0 },
    'Declan': { fouls: 0, fta: 8, ftm: 6, layups: 0 },
    'Kush': { fouls: 5, fta: 3, ftm: 2, layups: 5 }
  },

  // Game 3
  {
    'Declan': { fouls: 3, fta: 2, ftm: 1, layups: 1 },
    'Lucas': { fouls: 0, fta: 0, ftm: 0, layups: 2 },
    'Ryan': { fouls: 0, fta: 7, ftm: 3, layups: 6 },
    'Blake': { fouls: 2, fta: 1, ftm: 0, layups: 1 },
    'Kush': { fouls: 2, fta: 0, ftm: 0, layups: 2 }
  }
]

// Injury data fetched from backend, read-only
const injuredData = [
  // Game 1
  {},

  // Game 2 - Ryan was injured this game
  { 'Nathan': true,
    'Julian': true},

  // Game 3 - Kush was injured this game
  { 'Nathan': true,
    'Julian': true,
    'Paul': true}
]

// Game names mapping
const gameNames = ['vs. Dwight', 'vs. Hackley', 'vs. Dalton']

function getPlayerGameStats(gameIndex, player) {
  const playerData = gamesData[gameIndex][player]
  if (!playerData) {
    return { fouls: 0, fta: 0, ftm: 0, layups: 0 }
  }
  return playerData
}

function perGameStats(gameIndex, player) {
  const { fouls, fta, ftm, layups } = getPlayerGameStats(gameIndex, player)
  const points = (layups * 2) + ftm
  return { fouls, fta, ftm, layups, points }
}

const wins = 2
const losses = 1
const totalGamesPlayed = wins + losses
const winPercentage = computed(() => (wins / totalGamesPlayed) * 100)

const overallStats = computed(() => {
  const totals = {}
  for (const player of allPlayers) {
    totals[player] = { fouls: 0, fta: 0, ftm: 0, layups: 0, points: 0 }
  }

  gamesData.forEach((_, gameIndex) => {
    for (const player of allPlayers) {
      const stats = getPlayerGameStats(gameIndex, player)
      totals[player].fouls += stats.fouls
      totals[player].fta += stats.fta
      totals[player].ftm += stats.ftm
      totals[player].layups += stats.layups
    }
  })

  for (const player of allPlayers) {
    totals[player].points = (totals[player].layups * 2) + totals[player].ftm
  }

  return totals
})

const totalPointsAllPlayers = computed(() => {
  return Object.values(overallStats.value).reduce((sum, p) => sum + (p.points ?? 0), 0)
})

const totalFoulsAllPlayers = computed(() => {
  return Object.values(overallStats.value).reduce((sum, p) => sum + (p.fouls ?? 0), 0)
})

// Calculate average points for a player excluding injured games & Game 1 (index=0)
function calcAveragePoints(player) {
  let sumPoints = 0
  let count = 0

  gamesData.forEach((game, i) => {
    // Skip Game 1 (index 0)
    if (i === 0) return

    // Skip if injured this game
    if (injuredData[i]?.[player]) return

    const stats = perGameStats(i, player)
    sumPoints += stats.points
    count++
  })

  if (count === 0) return 0
  return sumPoints / count
}

// Sort players by average points descending
const sortedPlayers = computed(() => {
  return [...allPlayers].sort((a, b) => calcAveragePoints(b) - calcAveragePoints(a))
})

// Modal handling
const showModal = ref(false)
const selectedPlayer = ref(null)

function openModal(player) {
  selectedPlayer.value = player
  showModal.value = true
}

function closeModal() {
  showModal.value = false
  selectedPlayer.value = null
}

function playerGameStats(player) {
  if (!player) return []
  return gamesData.map((_, i) => perGameStats(i, player))
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
