<!-- footer.vue -->
<template>
  <!-- Footer -->
  <footer class="bg-gray-100 dark:bg-gray-800 text-gray-500 dark:text-gray-500 p-6 text-center transition-colors duration-300">
    <p class="flex flex-col sm:flex-row justify-center items-center">
      <span>&copy; 2025 BetterConnect. All rights reserved.</span>
      <span class="hidden sm:inline mx-2">|</span>
      <a
          href="/privacy"
          class="dark:accent-gray-500 hover:underline mt-2 sm:mt-0"
          aria-label="Privacy Policy"
      >
        Privacy Policy
      </a>
      <span class="hidden sm:inline mx-2">|</span>
      <a
          href="/terms"
          class="dark:accent-gray-500 hover:underline mt-2 sm:mt-0"
          aria-label="Terms of Service"
      >
        Terms of Service
      </a>
    </p>
  </footer>
</template>

<script setup>
</script>

<style scoped>
/* No additional styles needed as classes handle theming */
</style>
